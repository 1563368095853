var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-control",attrs:{"id":_vm.myid}},[_c('button',{class:_vm.buttonText ? '' : 'sub-control-button',on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleSubCtrlMenu.apply(null, arguments)}}},[(_vm.buttonText)?_c('span',[_vm._v(_vm._s(_vm.buttonText))]):_c('span',[_vm._v("︙")])]),(_vm.showSubCtrlMenu)?_c('div',{class:[
            'sub-control-menu',
            {
                vartical: !_vm.customlayout && _vm.horizontal,
                horizontalLayout: _vm.horizontal
            }
        ]},[_vm._t("default")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }