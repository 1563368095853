<template>
    <div class="sub-control" :id="myid">
        <button
            :class="buttonText ? '' : 'sub-control-button'"
            @click.stop.prevent="toggleSubCtrlMenu"
        >
            <span v-if="buttonText">{{ buttonText }}</span>
            <span v-else>&#xFE19;</span>
        </button>
        <div
            :class="[
                'sub-control-menu',
                {
                    vartical: !customlayout && horizontal,
                    horizontalLayout: horizontal
                }
            ]"
            v-if="showSubCtrlMenu"
        >
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: "MopSubControlMenu",
    props: {
        id: String,
        buttonText: String,
        horizontal: Boolean,
        customlayout: Boolean
    },
    data: function() {
        return {
            myid: this.id,
            showSubCtrlMenu: false
        };
    },
    watch: {
        "$store.state.clickedTarget": function(ele) {
            if (ele) {
                let tg = ele.closest(".sub-control");
                if (!tg || tg.id != this.myid) {
                    this.showSubCtrlMenu = false;
                }
            }
        }
    },
    methods: {
        toggleSubCtrlMenu: function() {
            this.showSubCtrlMenu = !this.showSubCtrlMenu;
        }
    },
    created: function() {
        if (!this.myid) {
            this.$store.commit("inclementIdseq");
            this.myid = "mopsubcontrolmenu" + this.$store.state.idseq;
        }
    }
};
</script>
<style scoped>
/* ----- */
.sub-control {
    position: relative;
    align-self: end;
    display: inline-block;
}
.sub-control-button {
    border-radius: 50%;
    font-size: 1.2rem;
    border-style: none;
    width: 25px;
    height: 25px;
    padding: 0;
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    /* box-shadow: none; */
}
.sub-control-menu {
    position: absolute;
    z-index: 100;
    top: 1.5rem;
    right: 0;
    background-color: var(--main-context-bg-color);
    color: var(--main-text-color);
    padding: 10px;
    box-shadow: var(--panel-shadow);
}
.sub-control-menu.vartical {
    /* display: none; */
    /* }
.sub-control:focus-within .sub-control-menu {*/
    display: flex;
    width: 100px;
    flex-direction: column;
    text-align: right;
}
.sub-control-menu button {
    margin: 10px;
    font-size: 1rem;
}

.sub-control-menu.horizontalLayout {
    display: flex;
    flex-direction: row;
    width: auto;
}
.sub-control-menu.horizontalLayout > * {
    margin: 10px;
}
</style>
