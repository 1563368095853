<script>
import DateUtils from "@/DateUtils";

export default {
    name: "LogListMixin",
    methods: {
        viewdiv: function(divkey, code) {
            let ret = "";
            let div = this.divs[divkey][code];
            if (div != null) {
                ret = div.contentshort;
            }
            return ret;
        },
        viewcud: function(code) {
            let ret = "";
            switch (code) {
                case "C":
                    ret = "作成";
                    break;
                case "U":
                    ret = "修正";
                    break;
                case "D":
                    ret = "削除";
                    break;
                default:
                    ret = "-";
            }
            return ret;
        },
        settingPre: function(dlist, key, premap) {
            const mx = dlist.length;
            if (!premap) {
                premap = {};
            }
            for (let i = mx - 1; i > -1; i--) {
                let item = dlist[i];
                let kv = item[key];
                if (!premap[kv]) {
                    premap[kv] = item;
                }
                item["pre"] = premap[kv];
                premap[kv] = item;
            }
        },
        modclass: function(d, pname, ptype) {
            let ret = "";
            let cv = d[pname];
            if (d.cud == "D") {
                ret = "deleted";
            } else if (d.pre) {
                let pv = d.pre[pname];
                if (ptype == "date") {
                    cv = DateUtils.formatDate(cv);
                    pv = DateUtils.formatDate(pv);
                }
                if (cv != pv) {
                    ret = "modified";
                }
            }
            return ret;
        }
    }
};
</script>

<style scope>
table.logtable thead tr > * {
    text-align: center;
    position: sticky;
    top: 0;
    background-color: var(--title-bg-color);
    color: var(--title-text-color);
}
table.logtable thead tr > th.rownum {
    background-color: var(--main-context-bg-color);
}

table.logtable td {
    vertical-align: top;
    border-right-style: dotted;
    border-left-style: dotted;
}
table.logtable td:last-child {
    vertical-align: top;
    border-right-style: none;
}
table.logtable td .title {
    font-size: 0.5em;
    display: block;
}
table.logtable td {
    padding: 3px 10px;
}
table.logtable td .id {
    font-size: 0.75em;
    color: var(--text-weak-color);
}
table.logtable td .id::after {
    content: " : ";
}
table.logtable td .code {
    font-size: 0.75em;
}
table.logtable td .code::after {
    content: " : ";
}
table.logtable td .stsname {
    font-size: 0.75em;
    color: var(--text-weak-color);
}
table.logtable td .stsname::before {
    content: "(";
}
table.logtable td .stsname::after {
    content: ")";
}
table.logtable tbody:nth-child(2n + 1) {
    background: var(--odd-bg-color);
}

.modified {
    color: var(--error-text-color);
    font-size: 120%;
}
.deleted {
    text-decoration-line: line-through;
    text-decoration-style: double;
}
</style>
