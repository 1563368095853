<template>
    <div>
        <div class="loader" v-if="loading" key="loading">Now loading...</div>
        <transition name="loading" mode="out-in">
            <div class="salestargettable" v-if="!loading" key="datatable">
                <transition name="strans" mode="out-in">
                    <table class="salestarget" v-if="!updating">
                        <thead>
                            <tr>
                                <th rowspan="2"></th>
                                <th rowspan="2">目標金額</th>
                                <th
                                    rowspan="2"
                                    class="man-month"
                                    v-if="visibleManMonth"
                                >
                                    工数
                                </th>
                                <th rowspan="2" v-if="visibleManMonth">
                                    目標単金
                                </th>

                                <th rowspan="2">受注金額</th>

                                <th rowspan="2">発注金額</th>
                                <th
                                    colspan="3"
                                    v-if="visibleDetail"
                                    class="detail"
                                    style="text-align:center"
                                >
                                    発注金額内訳
                                </th>

                                <th rowspan="2">内部売上</th>

                                <th rowspan="2">実績 - 目標</th>
                                <th rowspan="2" class="achievement-rate">
                                    達成率
                                </th>
                            </tr>

                            <tr>
                                <th
                                    class="outsourcing-cost detail"
                                    v-if="visibleDetail"
                                >
                                    外注費
                                </th>
                                <th
                                    class="purchase-amount detail"
                                    v-if="visibleDetail"
                                >
                                    仕入
                                </th>
                                <th
                                    class="commission-paid detail"
                                    v-if="visibleDetail"
                                >
                                    支払手数料
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <template v-for="d in depts">
                                <tr
                                    class="rootDept"
                                    v-if="d.department_div == 0"
                                    :key="d.department_id"
                                >
                                    <th class="dept">
                                        {{ getDepartmentName(d) }}
                                        <div
                                            v-if="state.visibleLog"
                                            class="comparedata"
                                        >
                                            {{ state.logdate | date }}
                                        </div>
                                        <div
                                            v-if="state.visibleCmp"
                                            class="comparedata"
                                        >
                                            {{ dispCmpYear() }}
                                        </div>
                                    </th>
                                    <td class="target-amount amount">
                                        <div class="value">
                                            {{ getTgtAmt(d) | amountf }}
                                        </div>
                                    </td>
                                    <td
                                        class="man-month"
                                        v-if="visibleManMonth"
                                    >
                                        <div class="value">
                                            {{ getManMonth(d) | amountf(0, 2) }}
                                        </div>
                                    </td>
                                    <td
                                        class="man-month-target"
                                        v-if="visibleManMonth"
                                    >
                                        <div class="value">
                                            {{ getManMonthTarget(d) | amountf }}
                                        </div>
                                    </td>

                                    <td
                                        class="so amount"
                                        @click="showDetail(d)"
                                    >
                                        <SalesTargetTableCell
                                            :state="state"
                                            :cmp="visibleCmp"
                                            :obj="getDeptObj(d)"
                                            prp="sales_amount"
                                        >
                                        </SalesTargetTableCell>
                                    </td>
                                    <td
                                        class="po amount"
                                        @click="showDetail(d)"
                                    >
                                        <SalesTargetTableCell
                                            :state="state"
                                            :cmp="visibleCmp"
                                            :obj="getDeptObj(d)"
                                            prp="costofsales_amount"
                                        >
                                        </SalesTargetTableCell>
                                    </td>

                                    <td
                                        class="outsourcing-cost amount detail"
                                        v-if="visibleDetail"
                                    >
                                        <SalesTargetTableCell
                                            :state="state"
                                            :cmp="visibleCmp"
                                            :obj="getDeptObj(d)"
                                            prp="outsourcing_cost"
                                        >
                                        </SalesTargetTableCell>
                                    </td>
                                    <td
                                        class="purchase-amount amount detail"
                                        v-if="visibleDetail"
                                    >
                                        <SalesTargetTableCell
                                            :state="state"
                                            :cmp="visibleCmp"
                                            :obj="getDeptObj(d)"
                                            prp="purchase_amount"
                                        >
                                        </SalesTargetTableCell>
                                    </td>
                                    <td
                                        class="commission-paid amount detail"
                                        v-if="visibleDetail"
                                    >
                                        <SalesTargetTableCell
                                            :state="state"
                                            :cmp="visibleCmp"
                                            :obj="getDeptObj(d)"
                                            prp="commission_paid"
                                        >
                                        </SalesTargetTableCell>
                                    </td>

                                    <td
                                        class="marginal-profit amount"
                                        @click="showDetail(d)"
                                    >
                                        <SalesTargetTableCell
                                            :state="state"
                                            :cmp="visibleCmp"
                                            :obj="getMrgPfrObj(d)"
                                            prp="mrg"
                                        >
                                        </SalesTargetTableCell>
                                    </td>

                                    <td class="target-difference amount">
                                        {{ getTgtDiff(d) | amountf }}
                                    </td>
                                    <td class="achievement-rate rate">
                                        {{ getTgtRate(d) | rate(2) }}
                                    </td>
                                </tr>

                                <template v-if="visibleBranch && d.children">
                                    <tr
                                        class="branchDept"
                                        v-for="ko in d.children"
                                        :key="ko.department_id"
                                    >
                                        <th class="dept">
                                            <span class="child">{{
                                                getDepartmentName(ko)
                                            }}</span>
                                            <div
                                                v-if="state.visibleLog"
                                                class="comparedata"
                                            >
                                                {{ state.logdate | date }}
                                            </div>
                                            <div
                                                v-if="state.visibleCmp"
                                                class="comparedata"
                                            >
                                                {{ dispCmpYear() }}
                                            </div>
                                        </th>
                                        <td class="target-amount amount">
                                            <div class="value">
                                                {{ getTgtAmt(ko) | amountf }}
                                            </div>
                                        </td>
                                        <td
                                            class="man-month"
                                            v-if="visibleManMonth"
                                        >
                                            <div class="value">
                                                {{
                                                    getManMonth(ko)
                                                        | amountf(0, 2)
                                                }}
                                            </div>
                                        </td>
                                        <td
                                            class="man-month-target"
                                            v-if="visibleManMonth"
                                        >
                                            <div class="value">
                                                {{
                                                    getManMonthTarget(ko)
                                                        | amountf
                                                }}
                                            </div>
                                        </td>
                                        <td
                                            class="so amount"
                                            @click="showDetail(ko)"
                                        >
                                            <SalesTargetTableCell
                                                :state="state"
                                                :cmp="visibleCmp"
                                                :obj="getDeptObj(ko)"
                                                prp="sales_amount"
                                            >
                                            </SalesTargetTableCell>
                                        </td>
                                        <td
                                            class="po amount"
                                            @click="showDetail(ko)"
                                        >
                                            <SalesTargetTableCell
                                                :state="state"
                                                :cmp="visibleCmp"
                                                :obj="getDeptObj(ko)"
                                                prp="costofsales_amount"
                                            >
                                            </SalesTargetTableCell>
                                        </td>

                                        <td
                                            class="outsourcing-cost amount detail"
                                            v-if="visibleDetail"
                                        >
                                            <SalesTargetTableCell
                                                :state="state"
                                                :cmp="visibleCmp"
                                                :obj="getDeptObj(ko)"
                                                prp="outsourcing_cost"
                                            >
                                            </SalesTargetTableCell>
                                        </td>
                                        <td
                                            class="purchase-amount amount detail"
                                            v-if="visibleDetail"
                                        >
                                            <SalesTargetTableCell
                                                :state="state"
                                                :cmp="visibleCmp"
                                                :obj="getDeptObj(ko)"
                                                prp="purchase_amount"
                                            >
                                            </SalesTargetTableCell>
                                        </td>
                                        <td
                                            class="commission-paid amount detail"
                                            v-if="visibleDetail"
                                        >
                                            <SalesTargetTableCell
                                                :state="state"
                                                :cmp="visibleCmp"
                                                :obj="getDeptObj(ko)"
                                                prp="commission_paid"
                                            >
                                            </SalesTargetTableCell>
                                        </td>

                                        <td
                                            class="marginal-profit amount"
                                            @click="showDetail(ko)"
                                        >
                                            <SalesTargetTableCell
                                                :state="state"
                                                :cmp="visibleCmp"
                                                :obj="getMrgPfrObj(ko)"
                                                prp="mrg"
                                            >
                                            </SalesTargetTableCell>
                                        </td>

                                        <td class="target-difference amount">
                                            {{ getTgtDiff(ko) | amountf }}
                                        </td>
                                        <td class="achievement-rate rate">
                                            {{ getTgtRate(ko) | rate(2) }}
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th style="text-align:left;">
                                    全社
                                    <div
                                        v-if="state.visibleLog"
                                        class="comparedata"
                                    >
                                        {{ state.logdate | date }}
                                    </div>
                                    <div
                                        v-if="state.visibleCmp"
                                        class="comparedata"
                                    >
                                        {{ dispCmpYear() }}
                                    </div>
                                </th>
                                <td class="target-amount amount">
                                    <div class="value">
                                        {{ getTgtAmt(null) | amountf }}
                                    </div>
                                </td>
                                <td class="man-month" v-if="visibleManMonth">
                                    <div class="value">
                                        {{ getManMonth(null) | amountf(0, 2) }}
                                    </div>
                                </td>
                                <td
                                    class="man-month-target"
                                    v-if="visibleManMonth"
                                >
                                    <div class="value">
                                        {{ getManMonthTarget(null) | amountf }}
                                    </div>
                                </td>
                                <td class="so amount">
                                    <SalesTargetTableCell
                                        :state="state"
                                        :cmp="visibleCmp"
                                        :obj="totalValues"
                                        prp="sales_amount"
                                    >
                                    </SalesTargetTableCell>
                                </td>
                                <td class="po amount">
                                    <SalesTargetTableCell
                                        :state="state"
                                        :cmp="visibleCmp"
                                        :obj="totalValues"
                                        prp="costofsales_amount"
                                    >
                                    </SalesTargetTableCell>
                                </td>
                                <td
                                    class="outsourcing-cost amount detail"
                                    v-if="visibleDetail"
                                >
                                    <SalesTargetTableCell
                                        :state="state"
                                        :cmp="visibleCmp"
                                        :obj="totalValues"
                                        prp="outsourcing_cost"
                                    >
                                    </SalesTargetTableCell>
                                </td>
                                <td
                                    class="purchase-amount amount detail"
                                    v-if="visibleDetail"
                                >
                                    <SalesTargetTableCell
                                        :state="state"
                                        :cmp="visibleCmp"
                                        :obj="totalValues"
                                        prp="purchase_amount"
                                    >
                                    </SalesTargetTableCell>
                                </td>
                                <td
                                    class="commission-paid amount detail"
                                    v-if="visibleDetail"
                                >
                                    <SalesTargetTableCell
                                        :state="state"
                                        :cmp="visibleCmp"
                                        :obj="totalValues"
                                        prp="commission_paid"
                                    >
                                    </SalesTargetTableCell>
                                </td>

                                <td class="marginal-profit amount">
                                    <SalesTargetTableCell
                                        :state="state"
                                        :cmp="visibleCmp"
                                        :obj="getMrgPfrObj(null)"
                                        prp="mrg"
                                    >
                                    </SalesTargetTableCell>
                                </td>

                                <td class="target-difference amount">
                                    {{ getTgtDiff(null) | amountf }}
                                </td>
                                <td class="achievement-rate rate">
                                    {{ getTgtRate(null) | rate(2) }}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import DateUtils from "@/DateUtils";
import SalesTargetTableCell from "@/views/salestarget/SalesTargetTableCell.vue";

export default {
    name: "SalesTargetTable",
    components: {
        SalesTargetTableCell
    },
    model: {
        prop: "model"
    },
    props: {
        startdate: Date,
        enddate: Date,
        refreshCount: Number,
        visibleBranch: Boolean,
        visibleDetail: Boolean,
        visibleManMonth: Boolean,
        salesorderStatus: Array,
        purchaseorderStatus: Array,
        logdate: Date,
        logfiscalyear: Object,
        visibleLog: Boolean,
        visibleCmp: Boolean,
        logstartdate: Date,
        logenddate: Date,
        makeParams: Function,
        state: Object,
        dtlobj: Object
    },
    data: function() {
        return {
            depts: [],
            deptsCurrent: [],
            datalist: [],
            loading: true,
            updating: false,
            totalValues: {},
            deptValues: [],
            loadcountA: 0,
            loadcountB: 0
        };
    },
    watch: {
        refreshCount: function() {
            this.loadcountA++;
            this.loading = true;
            setTimeout(() => {
                this.loadcountB++;
                if (this.loadcountA == this.loadcountB) {
                    this.loaddepts(this.loaddata);
                } else {
                    this.loading = false;
                }
            }, 500);
            // await this.loadsummary();
        },
        visibleBranch: function() {
            this.updating = true;
        },
        visibleManMonth: function() {
            this.updating = true;
        },
        visibleDetail: function() {
            this.updating = true;
        }
    },
    created: async function() {
        if (this.startdate) {
            await this.loaddepts(this.loaddata);
        }
    },
    methods: {
        showDetail: function(d) {
            this.dtlobj.department = {
                id: d.department_id,
                code: d.department_code,
                name_short: d.department_name_short,
                name_long: d.department_name_long
            };
            this.dtlobj.show = true;
        },
        initdeptlist: function(deptlist) {
            let pobj = {};
            for (let dept of deptlist) {
                if (dept.department_div == 0) {
                    let d = pobj[dept.department_id];
                    if (d) {
                        if (!d.others) {
                            d.others = [];
                        }
                        d.others.push(dept);
                    } else {
                        pobj[dept.department_id] = dept;
                    }
                }
            }

            for (let dept of deptlist) {
                if (dept.department_div == 1) {
                    let p = pobj[dept.upper_department_id];
                    if (p) {
                        if (!p.children) {
                            p.children = {};
                        }
                        let c = p.children["x" + dept.department_id];
                        if (c) {
                            if (!c.others) {
                                c.others = [];
                            }
                            c.others.push(dept);
                        } else {
                            p.children["x" + dept.department_id] = dept;
                        }
                        // p.children.push(dept);
                    }
                }
            }
            return pobj;
        },
        loaddepts: function(func) {
            this.loading = true;
            let params = {
                period: DateUtils.toRequestRangeDate(
                    this.startdate,
                    this.enddate
                )
            };
            return this.readDataToArray(this.makeurl("dept", params), list => {
                this.deptsCurrent = this.initdeptlist(list);
                this.depts = JSON.parse(JSON.stringify(this.deptsCurrent));
                if (func) {
                    func();
                }
            });
        },
        loaddeptsComp: function(params, func) {
            return this.readDataToArray(this.makeurl("dept", params), list => {
                let deptsComp = this.initdeptlist(list);
                this.mergeDepts(deptsComp);
                if (func) {
                    func();
                }
            });
        },
        mergeDepts: function(deptsComp) {
            for (let did in deptsComp) {
                let basedept = this.depts[did];
                let dept = deptsComp[did];
                if (basedept) {
                    for (let cdid in dept.children) {
                        let basec = basedept.children[cdid];
                        if (!basec) {
                            let xcdept = dept.children[cdid];
                            xcdept.department_name_short =
                                "※ " + xcdept.department_name_short;
                            basedept.children[cdid] = dept.children[cdid];
                        }
                    }
                } else {
                    dept.department_name_short =
                        "※ " + dept.department_name_short;
                    this.depts[did] = dept;
                    for (let cdid in dept.children) {
                        let xcdept = dept.children[cdid];
                        xcdept.department_name_short =
                            "※ " + xcdept.department_name_short;
                    }
                }
            }
        },
        loadsummaryDef: function(url, suffix, aDepts) {
            return this.readDataToArray(
                url,
                list => {
                    let nlist = [];
                    for (let d of list) {
                        nlist[d.department_id] = d;
                    }
                    // this.stats = nlist;

                    const cols = [
                        "sales_amount",
                        "costofsales_amount",
                        "outsourcing_cost",
                        "purchase_amount",
                        "commission_paid",
                        "target_amount",
                        "man_month"
                    ];
                    for (let col of cols) {
                        this.totalValues[col + suffix] = 0;
                    }

                    for (let did in aDepts) {
                        let dept = aDepts[did];
                        let d0v = this.deptValues[did];
                        if (!d0v) {
                            d0v = {};
                        }
                        for (let col of cols) {
                            d0v[col + suffix] = 0;
                        }

                        for (let cdid in dept.children) {
                            const c = cdid.substring(1);
                            let d1v = this.deptValues[c];
                            if (!d1v) {
                                d1v = {};
                            }
                            for (let col of cols) {
                                let v = this.getDataValue(nlist, c, col);
                                col += suffix;
                                d1v[col] = v;
                                d0v[col] += v;
                                this.totalValues[col] += v;
                            }
                            this.deptValues[c] = d1v;
                        }
                        this.deptValues[did] = d0v;
                    }
                },
                null,
                null,
                "salestarget" + suffix
            );
        },
        loadsummary: function() {
            let params = this.makeParams();
            let url = this.makeurl("stgt", params);
            return this.loadsummaryDef(url, "", this.depts);
        },
        loadsummaryCmp: async function() {
            let params = this.makeParams("cmp");
            await this.loaddeptsComp(params);
            let url = this.makeurl("stgt", params);
            return this.loadsummaryDef(url, "_cmp", this.depts);
        },
        loadsummaryLog: async function() {
            let params = this.makeParams();
            // await this.loaddeptsComp({ rdate: params["log"] });
            // await this.loaddeptsComp(params);

            // let comp = this.getCompany();
            // let fh = DateUtils.getFiscalYearAndHalfsign(comp, this.state.logdate);
            // let dts = DateUtils.getFiscalRangeDate(
            //     comp,
            //     fh.fiscalyear,
            //     this.state.perioddiv
            // );
            // params["period"] = this.dateTo8Str(dts.start) + "-" + this.dateTo8Str(dts.end);

            let url = this.makeurl("stgt", "log", params);
            return this.loadsummaryDef(url, "_log", this.depts);
        },
        loaddata: async function() {
            this.depts = JSON.parse(JSON.stringify(this.deptsCurrent));
            if (this.state.visibleLog && this.state.visibleCmp) {
                await Promise.all([
                    this.loadsummary(),
                    this.loadsummaryLog(),
                    this.loadsummaryCmp()
                ]);
            } else if (this.state.visibleLog) {
                await Promise.all([this.loadsummary(), this.loadsummaryLog()]);
            } else if (this.state.visibleCmp) {
                await Promise.all([this.loadsummary(), this.loadsummaryCmp()]);
            } else {
                await this.loadsummary();
            }

            this.loading = false;
        },
        getDataValue: function(stats, department_id, key) {
            let ret = 0;
            let s = stats[department_id];
            if (s && s[key]) {
                ret = s[key];
                ret = new Number(ret);
            }
            return ret;
        },
        getDateRangeParam: function() {
            return (
                this.dateTo8Str(this.startdate) +
                "-" +
                this.dateTo8Str(this.enddate)
            );
        },
        getDeptObj: function(d) {
            return this.deptValues[d.department_id];
        },
        getStatValue: function(d, key) {
            let ret = 0;
            if (d == null) {
                ret = this.totalValues[key];
            } else {
                let s = this.deptValues[d.department_id];
                if (s) {
                    ret = s[key];
                }
            }

            return ret;
        },
        getDepartmentName: function(d) {
            let ret = d.department_name_short;
            if (d.others) {
                for (const o of d.others) {
                    ret += "," + o.department_name_short;
                }
            }
            return ret;
        },

        getMrgPfrObj: function(d) {
            let obj = [];
            obj["mrg"] = this.getMrgPrf(d);
            obj["mrg_log"] = this.getMrgPrf(d, "_log");
            obj["mrg_cmp"] = this.getMrgPrf(d, "_cmp");
            return obj;
        },

        getMrgPrf: function(d, suffix) {
            let suf = suffix;
            if (!suf) {
                suf = "";
            }
            let a = this.getStatValue(d, "sales_amount" + suf);
            let b = this.getStatValue(d, "costofsales_amount" + suf);
            return a - b;
        },

        getMrgPrfDiff: function(d, suffix) {
            let a = this.getMrgPrf(d);
            let b = this.getMrgPrf(d, suffix);
            return a - b;
        },
        getTgtAmt: function(d) {
            return this.getStatValue(d, "target_amount");
        },
        getTgtDiff: function(d, suffix) {
            let suf = suffix;
            if (!suf) {
                suf = "";
            }
            return this.calcTargetDifference(
                d,
                "sales_amount" + suf,
                "costofsales_amount" + suf,
                "target_amount" + suf
            );
        },

        getTgtDiffDiff: function(d, suffix) {
            let a = this.getTgtDiff(d);
            let b = this.getTgtDiff(d, suffix);
            return a - b;
        },

        calcTargetDifference: function(
            d,
            keySOAmount,
            keyCOSAmount,
            keyTgtAmount
        ) {
            let a = this.getStatValue(d, keySOAmount);
            let b = this.getStatValue(d, keyCOSAmount);
            let c = this.getStatValue(d, keyTgtAmount);
            return a - b - c;
        },

        getTgtRate: function(d, suffix) {
            let suf = suffix;
            if (!suf) {
                suf = "";
            }
            return this.calcTargetRate(
                d,
                "sales_amount" + suf,
                "costofsales_amount" + suf,
                "target_amount" + suf
            );
        },

        getTgtRateDiff: function(d, suffix) {
            let a = this.getTgtRate(d);
            let b = this.getTgtRate(d, suffix);
            return a - b;
        },
        calcTargetRate: function(d, keySOAmount, keyCOSAmount, keyTgtAmount) {
            let ret = NaN;
            let c = this.getStatValue(d, keyTgtAmount);
            if (c > 0) {
                let a = this.getStatValue(d, keySOAmount);
                let b = this.getStatValue(d, keyCOSAmount);
                ret = (a - b) / c;
            }
            return ret;
        },
        getManMonth: function(d) {
            return this.getStatValue(d, "man_month");
        },
        getManMonthTarget: function(d) {
            let ret = NaN;
            let m = this.getStatValue(d, "man_month");
            if (m > 0) {
                let t = this.getStatValue(d, "target_amount");
                ret = t / m;
                ret = this.round(ret, -3);
            }
            return ret;
        },
        download: function() {
            let params = this.makeParams();

            let filename = "売上達成率";
            for (const p in params) {
                let v = params[p];
                filename += "_";
                if (v) {
                    if (v instanceof Array) {
                        for (let c of v) {
                            filename += c;
                        }
                    } else {
                        filename += v;
                    }
                }
            }
            filename += ".xlsx";
            this.downloadData(this.makeurl("stgt", "xlsx", params), filename);
        },
        dispCmpYear: function() {
            let ret = this.state.cmpyearobj.key;
            if (ret) {
                // ret += "";
                // ret = "'" + ret.slice(-2);
                switch (this.state.perioddiv) {
                    case 0:
                        ret += "全";
                        break;
                    case 1:
                        ret += "上";
                        break;
                    case 2:
                        ret += "下";
                        break;
                }
            }
            return ret;
        }
    },
    updated: function() {
        this.$nextTick(() => (this.updating = false));
    }
};
</script>

<style scoped>
.salestargettable {
    position: relative;
    display: inline-block;
    max-width: 100%;
}

.salestargettable table {
    position: relative;
    margin: 0;
    padding: 0;
    border-collapse: separate;
    border-spacing: 0;
}

.salestargettable table tr > * {
    border-style: solid none none solid;
    border-width: 1px;
    background-color: var(--main-context-bg-color);
}

.salestargettable table td {
    text-align: right;
    font-size: 1rem;
    vertical-align: baseline;
}

.salestargettable table th {
    text-align: right;
    font-size: 1rem;
    vertical-align: baseline;
    background-color: var(--title-bg-color);
    color: var(--title-text-color);
    /* filter: grayscale(50%) brightness(98%); */
}

.salestargettable table tbody tr.branchDept {
    /* filter: brightness(95%); */
}

.salestargettable table tbody tr.branchDept > * {
    border-top-style: dashed;
    /* font-size: 75%; */
    /* letter-spacing: 1.75px; */
    filter: var(--children-filter);
}

.salestargettable table tbody th > .child::before {
    content: " - ";
    padding-right: 0.5rem;
    opacity: 0.25;
}

.salestargettable table tbody .so,
.salestargettable table tbody .po {
    cursor: pointer;
}

.salestargettable table thead tr:nth-child(1) > * {
    position: sticky;
    top: 4rem;
    /* background-color: #eee; */
    z-index: 2;
}

.salestargettable table thead tr:nth-child(2) > * {
    position: sticky;
    top: calc(4rem + 27px);
    /* background-color: #eee; */
    z-index: 2;
}

.salestargettable table thead tr > th {
    border-bottom-style: solid;
    vertical-align: bottom;
    text-align: center;
}

.salestargettable table thead tr:first-child > th.detail {
    border-bottom-style: none;
    border-bottom-width: 0;
}

.salestargettable thead tr:first-child > *:first-child {
    position: sticky;
    top: 4rem;
    left: 0;
    /* background-color: #eee; */
    border-right-style: solid;
    border-right-width: 1px;
    z-index: 3;
}

.salestargettable thead tr:first-child > *:last-child {
    border-right-style: solid;
    border-right-width: 1px;
    /* position: sticky;
    right: 0; */
}

.salestargettable table tbody tr.branchDept > *:first-child {
    /* background-color: #edd; */
}

.salestargettable tbody tr > *:first-child {
    position: sticky;
    left: 0;
    border-right-width: 1px;
    border-right-style: solid;
    /* background-color: #eee; */
    text-align: left;
    z-index: 2;
}

.salestargettable tbody tr > *:last-child {
    /* position: sticky;
    right: 0; */
    border-right-width: 1px;
    border-right-style: solid;
}

.salestargettable tbody tr:nth-child(even) > td {
    /* filter: contrast(95%); */
    /* background-color: hsl(333, 100%, 97%); */
}

.salestargettable table tfoot tr > * {
    position: sticky;
    bottom: 0;
    /* background-color: #eee; */
    border-top-style: double;
    border-top-width: 6px;
    z-index: 2;
}

.salestargettable tfoot tr > *:first-child {
    position: sticky;
    bottom: 0;
    left: 0;
    border-right-width: 1px;
    border-right-style: solid;
    /* background-color: #eee; */
    z-index: 3;
}

.salestargettable tfoot tr > * {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.salestargettable tfoot tr > *:last-child {
    border-right-width: 1px;
    border-right-style: solid;
    /* position: sticky;
    right: 0; */
}

.salestargettable tfoot tr td {
    /* filter: grayscale(80%) brightness(98%); */
}

.salestargettable tfoot tr td.detail {
    /* filter: brightness(95%); */
}

.salestargettable .detail {
    /* font-size: 0.75rem; */
    /* opacity: 0.65; */
    /* filter: brightness(95%); */
    /* filter: brightness(95%); */
    font-size: 90%;
    letter-spacing: 0.25px;
}

.salestargettable th.detail {
    /* opacity: 0.65; */
    /* filter: brightness(95%); */
    /* filter: grayscale(10%) brightness(95%); */
    font-size: 90%;
}

/* .salestargettable .achievement-rate {
    border-right-style: double;
    border-right-width: 6px;
} */
.salestargettable .achievement-rate + * {
    /* border-left-style: double;
    border-left-width: 6px; */
}

.salestargettable .amount {
    min-width: 8em;
    width: 8em;
}

.salestargettable .rate {
    min-width: 4em;
    font-size: 1.1em;
}

.salestargettable .value {
    height: 1rem;
}

.salestargettable .logdata {
    height: 1rem;
    opacity: 0.5;
}

.salestargettable .logdiff {
    height: 1rem;
    opacity: 0.5;
}

.strans-enter-active,
.strans-leave-active {
    /* animation: sizeScale 0.5s ease reverse; */
    transition: 0.25s;
}

.strans-enter,
.strans-leave-to {
    /* animation: sizeScale ease 0.5s; */
    opacity: 0;
}

@keyframes sizeScale {
    100% {
        /* width: 0px; */
        opacity: 0;
    }
}

/* ------------ */
.controlbox {
    padding: 10px;
}

/* ------- */
.comparedata {
    border-style: none;
    padding: 2px 0 3px 0;
    margin: 5px 0 0 0;
    font-size: 85%;
    height: 2.4rem;
    border-top: 1px dotted var(--border-color);
    text-align: right;
    color: var(--text-weak-color);
}
</style>
