<template>
    <div style="position:relative;">
        <div>
            <table class="logtable">
                <thead>
                    <tr>
                        <th class="rownum"></th>
                        <th>LOG ID</th>
                        <th>タイムスタンプ</th>
                        <th>操作アカウント</th>
                        <th>操作</th>
                        <th>売上ID</th>
                        <th>計上済</th>
                        <th>売上部署</th>
                        <th>売上計上日</th>
                        <th>売上金額</th>
                        <th>消費税率</th>
                        <th>備考</th>
                        <th>受注ID</th>
                        <th>受注ステータス</th>
                    </tr>
                </thead>
                <tbody v-for="(d, ix) in payload_data" :key="d.log_id">
                    <tr :class="targetClass(d)">
                        <th class="rownum">
                            {{ ix + 1 }}
                        </th>
                        <td>
                            {{ d.log_id }}
                        </td>
                        <td>
                            {{ d.log_tmsp | datetime }}
                        </td>
                        <td>
                            <span v-if="d.cud == 'C'">{{
                                d.create_account_id
                            }}</span>
                            <span v-else>{{ d.update_account_id }}</span>
                        </td>
                        <td>
                            {{ viewcud(d.cud) }}
                        </td>

                        <td>
                            <a
                                href="#"
                                @click.prevent="showSlsLogList(d.sales_id)"
                                >{{ d.sales_id }}</a
                            >
                        </td>
                        <td :class="modclass(d, 'record_flag')">
                            {{ d.record_flag | flag }}
                        </td>
                        <td :class="modclass(d, 'sales_department_id')">
                            <span class="id">{{ d.sales_department_id }} </span>
                            <span class="code">{{
                                d.sales_department_code
                            }}</span>
                            {{ d.sales_department_name_short }}
                        </td>
                        <td :class="modclass(d, 'record_date', 'date')">
                            {{ d.record_date | date }}
                        </td>

                        <td :class="['amount', modclass(d, 'sales_amount')]">
                            {{ d.sales_amount | amount }}
                        </td>
                        <td :class="['amount', modclass(d, 'tax_rate')]">
                            {{ d.tax_rate }} %
                        </td>
                        <td :class="modclass(d, 'sales_note')">
                            {{ d.sales_note }}
                        </td>
                        <td>
                            {{ d.salesorder_id }}
                        </td>
                        <td :class="modclass(d, 'salesorder_status')">
                            {{ d.salesorder_status }}
                            <span class="stsname">{{
                                viewdiv("sosts", d.salesorder_status)
                            }}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import LogListMixin from "@/views/LogListMixin.vue";

export default {
    name: "SalesLogList",
    mixins: [LogListMixin],
    props: {
        startdate: Date,
        enddate: Date,
        visibleBranch: Boolean,
        visibleDetail: Boolean,
        salesorderStatus: Array,
        makeParams: Function,
        slslogobj: Object
    },
    data: () => ({
        payload_data: [],
        last_data: []
    }),
    methods: {
        showSlsLogList: function(sales_id) {
            this.slslogobj.sales_id = sales_id;
            this.slslogobj.show = true;
        },
        execute: async function() {
            this.payload_data = [];
            this.last_data = [];
            const params = this.makeParams("log");
            const premap = {};

            await this.readDataToArray(
                this.makeurl("stgt", "log", "sls", "last", params),
                dlist => {
                    this.last_data = dlist;
                    const mx = dlist.length;
                    for (let i = mx - 1; i > -1; i--) {
                        let item = dlist[i];
                        let kv = item["sales_id"];
                        premap[kv] = item;
                    }
                }
            );
            await this.readDataToArray(
                this.makeurl("stgt", "log", "sls", "diff", params),
                dlist => {
                    this.settingPre(dlist, "sales_id", premap);
                    this.payload_data = dlist;
                }
            );
        },
        targetClass: function(d) {
            let ret = "";
            if (
                d.record_date < this.startdate ||
                d.record_date > this.enddate ||
                (this.salesorderStatus &&
                    this.salesorderStatus.length > 0 &&
                    !this.salesorderStatus.includes(d.salesorder_status))
            ) {
                ret = "notarget";
            }
            return ret;
        }
    },
    created: async function() {
        await this.loaddivs("sosts");
        await this.execute();
    }
};
</script>
<style scoped>
.notarget {
    opacity: 0.375;
}
</style>
