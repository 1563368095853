<template>
    <div class="salestarget">
        <div class="header1 page-header">
            <h1 class="title">売上達成率</h1>
            <div>
                <div style="display:inline-block;">
                    <OpSelectFiscalyear
                        v-model="fiscalyear"
                        label="会計年度"
                        @input="changedYear"
                        :company="getCompany()"
                        required
                        hideheader
                    />
                </div>
                <!-- <OpSelectArray
                            v-model="query.fiscalyear"
                            label="会計年度"
                            :modelList="getFiscalyearList()"
                            maxwidth="15rem"
                            @input="search"
                            nosudgest
                        /> -->
                <div style="display:inline-block;">
                    <OpRadioGroup
                        v-model="state.perioddiv"
                        v-slot="{ item }"
                        :choices="perioddivlist"
                        :itemKey="item => item.key"
                        @input="changedFilter"
                    >
                        {{ item.name }}
                    </OpRadioGroup>
                </div>
            </div>
            <div class="mpc mpc-inline-block">
                <MopSubControlMenu horizontal buttonText="表示">
                    <div>
                        <OpCheckbox
                            label="グループ"
                            v-model="visibleBranch"
                            name="visible_branch"
                        />
                    </div>
                    <div>
                        <OpCheckbox
                            label="内訳"
                            v-model="visibleDetail"
                            name="visible_detail"
                        />
                    </div>
                    <div>
                        <OpCheckbox
                            label="工数"
                            v-model="visibleManMonth"
                            name="visible_manmonth"
                        />
                    </div>
                </MopSubControlMenu>
            </div>
            <div class="xpc">
                <OpCheckbox
                    label="グループ"
                    v-model="visibleBranch"
                    name="visible_branch"
                />
            </div>
            <div class="xpc">
                <OpCheckbox
                    label="内訳"
                    v-model="visibleDetail"
                    name="visible_detail"
                />
            </div>
            <div class="xpc">
                <OpCheckbox
                    label="工数"
                    v-model="visibleManMonth"
                    name="visible_manmonth"
                />
            </div>
            <div>
                <button @click="openCompareSetting()">比較</button>
            </div>
            <button @click="download">
                <IconBase
                    iconName="Excel"
                    width="24"
                    height="24"
                    visibleName
                    v-slot="{ viewbox }"
                    ><IconDownload @viewbox="viewbox"
                /></IconBase>
            </button>
        </div>
        <div class="header2">
            <div class="soposts">
                <!-- 受注ステータス -->
                <OpDivMultiSelector
                    label="受注ステータス"
                    v-model="salesorder_status"
                    :divlist="sostslist"
                    name="sosts"
                    divViewType="status"
                    @input="changedFilter"
                />
            </div>
            <div class="soposts">
                <!-- 発注ステータス -->
                <OpDivMultiSelector
                    label="発注ステータス"
                    v-model="purchaseorder_status"
                    :divlist="postslist"
                    name="posts"
                    divViewType="status"
                    @input="changedFilter"
                />
            </div>
        </div>
        <div class="body">
            <div class="tableframe">
                <SalesTargetTable
                    :startdate="startdate"
                    :enddate="enddate"
                    :refreshCount="refreshCount"
                    :visibleBranch="visibleBranch"
                    :visibleDetail="visibleDetail"
                    :visibleManMonth="visibleManMonth"
                    :salesorderStatus="salesorder_status"
                    :purchaseorderStatus="purchaseorder_status"
                    :logfiscalyear="getFiscayYearObj(logfiscalyear)"
                    :logstartdate="logstartdate"
                    :logenddate="logenddate"
                    :makeParams="makeParams"
                    :state="state"
                    :dtlobj="dtlobj"
                />
            </div>
        </div>
        <OpModal
            title="比較表示"
            v-if="showCompareSetting"
            @close="showCompareSetting = false"
        >
            <div class="compareSetting">
                <fieldset>
                    <legend>
                        <OpCheckbox
                            label="履歴と比較表示"
                            v-model="compareSet.visibleLog"
                            name="visibleLog"
                        />
                    </legend>
                    <div>
                        <OpDateTextField
                            label="比較対象日"
                            v-model="compareSet.logdate"
                            name="log_date"
                            :maxdate="getCompareMaxDate()"
                            :mindate="getCompareMinDate()"
                        />
                    </div>
                </fieldset>
                <fieldset>
                    <legend>
                        <OpCheckbox
                            label="他の年度との比較表示"
                            v-model="compareSet.visibleCmp"
                            name="visibleCmp"
                        />
                    </legend>
                    <div style="display:inline-block;">
                        <OpSelectFiscalyear
                            v-model="compareSet.cmpyearobj"
                            label="比較対象会計年度"
                            :company="getCompany()"
                            :offsetyear="-1"
                        />
                    </div>
                </fieldset>
            </div>
            <div class="control-box">
                <button @click="showCompareSetting = false">
                    キャンセル
                </button>
                <button @click="decideCompareSetting()">
                    OK
                </button>
            </div>
        </OpModal>
        <OpModal
            title="売上編集履歴"
            v-if="showSlsLog"
            @close="showSlsLog = false"
        >
            <SalesTargetSalesLogList
                :makeParams="makeParams"
                :startdate="startdate"
                :enddate="enddate"
                :salesorderStatus="salesorder_status"
                :slslogobj="slslogobj"
            />
        </OpModal>
        <OpModal
            v-if="slslogobj.show"
            :title="'売上編集履歴 (売上ID : ' + slslogobj.sales_id + ')'"
            @close="slslogobj.show = false"
            v-slot="{ close }"
        >
            <SalesLogList v-model="slslogobj" @fin="close"></SalesLogList>
        </OpModal>
        <OpModal v-if="dtlobj.show" @close="dtlobj.show = false">
            <template v-slot:titlebar>
                <div style="font-size:1rem;min-height:3rem; min-width:740px;">
                    <div style="float:right;">
                        <span>
                            受注 :
                            <span v-for="(v, c) in viewSoStsDiv()" :key="c">
                                <span class="statusValue"
                                    >{{ c }}
                                    <!-- <div class="statusName">
                                        {{ v }}
                                    </div> -->
                                </span>
                            </span>
                        </span>
                        <span>
                            / 発注 :
                            <span v-for="(v, c) in viewPoStsDiv()" :key="c">
                                <span class="statusValue"
                                    >{{ c }}
                                    <!-- <div class="statusName">
                                        {{ v }}
                                    </div> -->
                                </span>
                            </span>
                        </span>

                        <div
                            style="transform:scale(0.875); text-align:right;"
                            v-if="state.visibleLog"
                        >
                            <OpCheckbox
                                label="差分のないデータも表示"
                                v-model="visibleAllDetail"
                                name="visible_branch"
                            />
                        </div>
                    </div>
                    <div style="padding-bottom:5px;">
                        {{ formatFiscalYear() }}

                        <span style="font-size:87.5%;padding-left:1rem">
                            ({{ startdate | date }} ~
                            {{ enddate | date }})</span
                        >
                    </div>
                    <div>
                        <!-- <span class="id" style="font-size:50%;">
                            {{ dtlobj.department.id }} :
                        </span> -->
                        <span class="code" style="font-size:75%;">
                            {{ dtlobj.department.code }} :
                        </span>
                        <span>
                            {{ dtlobj.department.name_long }}
                            ({{ dtlobj.department.name_short }})
                        </span>
                    </div>
                </div>
            </template>
            <template v-slot:default="{ close }">
                <SalesTargetDetail
                    @fin="close"
                    :startdate="startdate"
                    :enddate="enddate"
                    :salesorderStatus="salesorder_status"
                    :purchaseorderStatus="purchaseorder_status"
                    :department="dtlobj.department"
                    :logdate="state.logdate"
                    :visibleLog="state.visibleLog"
                    :perioddiv="state.perioddiv"
                    :visibleAllDetail="visibleAllDetail"
                ></SalesTargetDetail>
            </template>
        </OpModal>
    </div>
</template>

<script>
import SalesTargetTable from "@/views/salestarget/SalesTargetTable.vue";
import SalesTargetDetail from "@/views/salestarget/SalesTargetDetail.vue";
import SalesTargetSalesLogList from "@/views/salestarget/SalesTargetSalesLogList.vue";
import OpSelectFiscalyear from "@/components/OpSelectFiscalyear.vue";
import OpDivMultiSelector from "@/components/OpDivMultiSelector.vue";
import OpRadioGroup from "@/components/OpRadioGroup.vue";
import OpCheckbox from "@/components/OpCheckbox.vue";
import DateUtils from "@/DateUtils";
import MopSubControlMenu from "@/components/MopSubControlMenu.vue";
import SalesLogList from "@/views/opportunity/SalesLogList.vue";
import IconBase from "@/components/icons/IconBase.vue";
import IconDownload from "@/components/icons/IconDownload.vue";

export default {
    name: "SalesTarget",
    components: {
        SalesTargetTable,
        OpSelectFiscalyear,
        OpRadioGroup,
        OpCheckbox,
        OpDivMultiSelector,
        MopSubControlMenu,
        SalesTargetSalesLogList,
        SalesLogList,
        IconBase,
        IconDownload,
        SalesTargetDetail
    },
    props: {},
    data: function() {
        let comp = this.getCompany();
        let fh = DateUtils.getFiscalYearAndHalfsignToday(comp);
        fh.halfsign = 0;
        let dts = DateUtils.getFiscalRangeDate(
            comp,
            fh.fiscalyear,
            fh.halfsign
        );
        let today = new Date();
        let defaultcomparedate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - 7
        );
        return {
            depts: [],
            fiscalyear: fh.fiscalyear,
            startdate: dts.start,
            enddate: dts.end,
            salesorder_status: ["A", "B", "C", "D"],
            purchaseorder_status: ["A", "B", "C", "D"],
            refreshCount: 1,
            prelogCount: 0,
            precmpCount: 0,
            visibleBranch: false,
            visibleDetail: false,
            visibleManMonth: false,
            visibleAllDetail: false,
            initedLog: false,
            initedCmp: false,
            logstartdate: dts.start,
            logenddate: dts.end,
            perioddivlist: [
                { key: 0, name: "通期" },
                { key: 1, name: "上期" },
                { key: 2, name: "下期" }
            ],
            sostslist: {},
            postslist: {},
            showCompareSetting: false,
            compareSet: {},
            logfiscalyear: fh.fiscalyear - 1,
            showSlsLog: false,
            state: {
                cmpyearobj: this.getFiscayYearObj(fh.fiscalyear - 1),
                visibleLog: false,
                visibleCmp: false,
                logdate: defaultcomparedate,
                perioddiv: fh.halfsign
            },
            slslogobj: {
                opportunity_id: 0,
                salesorder_id: 0,
                sales_id: 0,
                show: false
            },
            dtlobj: {
                department: null,
                show: false
            }
        };
    },
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
    created: async function() {
        let dd = this.loaddivs("sosts", "posts");
        if (dd instanceof Promise) {
            dd.then(this.filterSopolist);
        } else {
            this.filterSopolist();
        }

        let dp = this.loaddeptlist();
        if (dp instanceof Promise) {
            dp.then(() => {
                this.depts = this.deptlist;
            });
        } else {
            this.depts = this.deptlist;
        }
    },
    methods: {
        openCompareSetting: function() {
            // Object.assign(this.compareSet, this.state);
            this.compareSet.cmpyearobj = Object.assign(
                {},
                this.state.cmpyearobj
            );
            this.compareSet.visibleLog = this.state.visibleLog;
            this.compareSet.visibleCmp = this.state.visibleCmp;
            this.compareSet.logdate = this.state.logdate;
            this.showCompareSetting = true;
        },
        formatFiscalYear: function() {
            let y = this.fiscalyear.key;
            if (!y) y = this.fiscalyear;
            let ey = this.getCompany().establishment_year;
            return DateUtils.formatFiscalYear(y, ey, this.state.perioddiv);
        },
        decideCompareSetting: function() {
            let doflag = false;
            if (
                this.compareSet.visibleCmp &&
                (!this.initedCmp ||
                    this.state.cmpyearobj.key != this.compareSet.cmpyearobj.key)
            ) {
                let dts = DateUtils.getFiscalRangeDate(
                    this.getCompany(),
                    this.compareSet.cmpyearobj.key,
                    this.state.perioddiv
                );
                this.logstartdate = dts.start;
                this.logenddate = dts.end;
                doflag = true;

                this.initedCmp = true;
            }

            if (
                this.compareSet.visibleLog &&
                (!this.initedLog ||
                    this.state.logdate != this.compareSet.logdate)
            ) {
                // doflag = true;
                this.initedLog = true;
            }
            doflag = true;
            this.state.cmpyearobj = Object.assign(
                {},
                this.compareSet.cmpyearobj
            );
            this.state.visibleLog = this.compareSet.visibleLog;
            this.state.visibleCmp = this.compareSet.visibleCmp;
            this.state.logdate = this.compareSet.logdate;

            if (doflag) this.refreshCount++;

            this.showCompareSetting = false;
        },
        changedFilter: function() {
            this.changedYear(this.getFiscayYearObj(this.fiscalyear));
        },
        getFiscayYearObj: function(fy) {
            let y = null;
            if (fy.key) {
                y = fy;
            } else {
                y = { key: fy };
            }
            return y;
        },
        changedYear: function(year) {
            let dts = DateUtils.getFiscalRangeDate(
                this.getCompany(),
                year.key,
                this.state.perioddiv
            );
            this.startdate = dts.start;
            this.enddate = dts.end;
            this.refreshCount++;
            //this.preCount = this.refreshCount;
            if (this.state.visibleLog) {
                this.prelogCount = this.refreshCount;
            }
            if (this.state.visibleCmp) {
                let dts = DateUtils.getFiscalRangeDate(
                    this.getCompany(),
                    this.compareSet.cmpyearobj.key,
                    this.state.perioddiv
                );
                this.logstartdate = dts.start;
                this.logenddate = dts.end;

                this.precmpCount = this.refreshCount;
            }
        },

        filterSopolist: function() {
            let slist = this.divs["sosts"];
            let ss = {};
            for (let v in slist) {
                if (v != "Z") {
                    ss[v] = slist[v];
                }
            }
            this.sostslist = ss;

            let plist = this.divs["posts"];
            let ps = {};
            for (let v in plist) {
                if (v != "Z") {
                    ps[v] = plist[v];
                }
            }
            this.postslist = ps;
        },
        getCompareMaxDate: function() {
            let ret = new Date();
            ret = new Date(
                ret.getFullYear(),
                ret.getMonth(),
                ret.getDate() - 1
            );
            return ret;
            // return null;
        },
        getCompareMinDate: function() {
            // return this.startdate;
            return null;
        },
        makeParams: function(paramtype) {
            let params = {};
            let sd = null;
            let ed = null;
            if (paramtype == "cmp") {
                sd = this.logstartdate;
                ed = this.logenddate;
            } else {
                sd = this.startdate;
                ed = this.enddate;
            }
            params["period"] = this.dateTo8Str(sd) + "-" + this.dateTo8Str(ed);

            if (this.salesorder_status && this.salesorder_status.length > 0) {
                params["sosts"] = this.salesorder_status;
            }
            if (
                this.purchaseorder_status &&
                this.purchaseorder_status.length > 0
            ) {
                params["posts"] = this.purchaseorder_status;
            }
            if (paramtype == "log") {
                params["log"] = DateUtils.toRequestDate(
                    this.compareSet.logdate
                );
            } else {
                params["log"] = DateUtils.toRequestDate(this.state.logdate);
            }
            return params;
        },
        viewStsDiv: function(divcode, codes) {
            let ret = {};
            if (codes) {
                for (let c of codes) {
                    ret[c] = this.divs[divcode][c].contentshort;
                }
            }
            return ret;
        },
        viewSoStsDiv: function() {
            return this.viewStsDiv("sosts", this.salesorder_status);
        },
        viewPoStsDiv: function() {
            return this.viewStsDiv("posts", this.purchaseorder_status);
        },
        download: function() {
            let params = this.makeParams();

            let filename = "売上達成率";
            for (const p in params) {
                let v = params[p];
                filename += "_";
                if (v) {
                    if (v instanceof Array) {
                        for (let c of v) {
                            filename += c;
                        }
                    } else {
                        filename += v;
                    }
                }
            }
            filename += ".xlsx";
            this.downloadData(this.makeurl("stgt", "xlsx", params), filename);
        }
    }
};
</script>

<style scoped>
/* .control-box {
    position: sticky;
    bottom: 0;
    border-top: 1px solid #999;
    padding: 10px 0;
    margin-top: 10px;
    text-align: center;
} */
.salestarget .header1 {
    height: calc(4rem + 2px);
}

.salestarget .header2 {
    display: flex;
    justify-items: center;
    justify-content: center;

    width: 100%;
    text-align: center;
    vertical-align: top;
    z-index: 0;
    padding: 0;
}
.salestarget {
    position: relative;
    padding-left: 10px;
}
.salestarget .body {
    position: relative;
    width: 100%;
    padding: 0px;
    z-index: 0;
    background-color: var(--main-context-bg-color);
    margin: 0;
}
.salestarget .tableframe {
    position: relative;
    /* overflow-x: scroll; */
    /* height: calc(100vh - 160px); */
    /* overflow-y: auto; */
}
.tableframe {
    text-align: center;
}
.st-block {
    padding-bottom: 2rem;
}
.st-block table td {
    text-align: right;
    font-size: 0.9rem;
}
.outsourcing-cost,
.purchase-amount,
.commission-paid {
    font-size: 0.8rem;
}
.achievement-rate {
    border-right-style: double;
}
.dept2 {
    border-left-style: double;
    border-left-width: 3px;
}
.soposts {
    padding: 0px 10px;
}
.soposts > div {
    padding: 0px 0px;
    /* transform: scale(0.75); */
}

.logcondition > div {
    padding: 0px 10px;
    display: inline-block;
}
.control-box {
    position: sticky;
    bottom: 0px;
    z-index: 1;
    display: grid;

    padding: 10px 0;
    grid-template-columns: 150px 1fr 150px;
    justify-items: center;
}
.control-box > *:first-child {
    justify-self: left;
}
.control-box > *:last-child {
    justify-self: right;
}

/* --- */
.compareSetting {
    min-height: 300px;
}
.compareSetting > fieldset {
    margin: 0 0.5rem 1rem 1rem;
    padding: 0.5rem 0 1rem 2rem;
}
.compareSetting > fieldset > legend {
    margin-left: -3.25rem;
    padding: 0.5rem;
}
/* --- */

@media (max-width: 1279px) {
    .mpc {
        display: inherit;
    }
    .mpc-inline-block {
        display: inline-block;
    }
    .xpc {
        display: none;
    }
}
@media (min-width: 1280px) {
    .mpc {
        display: none;
    }
    .xpc {
        display: inherit;
    }
}
</style>
