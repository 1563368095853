<template>
    <div style="position:relative; min-height:200px;">
        <div class="loader" v-if="loading" key="loading">Now loading...</div>
        <transition name="loading" mode="out-in">
            <table class="dtable" v-if="!loading">
                <thead>
                    <tr>
                        <th rowspan="2">受注ID</th>
                        <th rowspan="2">受注件名</th>
                        <th
                            rowspan="2"
                            v-if="visibleLog"
                            class="bordercol"
                        ></th>
                        <th colspan="3" v-if="visibleLog">
                            {{ logdate | date }}
                        </th>
                        <th
                            rowspan="2"
                            v-if="visibleLog"
                            class="bordercol"
                        ></th>
                        <th colspan="3">現在</th>
                        <!-- <th rowspan="2" v-if="visibleLog"></th>
                        <th colspan="3" v-if="visibleLog">
                            差（現在 - {{ logdate | date }})
                        </th> -->
                    </tr>
                    <tr>
                        <th v-if="visibleLog">売上</th>
                        <th v-if="visibleLog">原価</th>
                        <th v-if="visibleLog">内部売上</th>
                        <th>売上</th>
                        <th>原価</th>
                        <th>内部売上</th>
                        <!-- <th v-if="visibleLog">売上</th>
                        <th v-if="visibleLog">原価</th>
                        <th v-if="visibleLog">内部売</th> -->
                    </tr>
                </thead>
                <tbody v-for="d in list_data" :key="d.salesorder_id">
                    <tr
                        v-if="visibleRow(d)"
                        :class="visibleRow(d) ? 'rownormal' : 'rowhide'"
                    >
                        <td>
                            {{ d.salesorder_id }}
                        </td>
                        <td style="min-width:20rem; white-space:normal;">
                            {{ d.salesorder_subject }}
                            <div><span class="code">{{ d.salesorder_customer_code }}</span>
                            {{ d.salesorder_customer_name }}</div>
                        </td>
                        <td v-if="visibleLog" class="bordercol"></td>
                        <td v-if="visibleLog" class="amount">
                            {{ d.p_sales_amount | amount("-") }}
                        </td>
                        <td v-if="visibleLog" class="amount">
                            {{ d.p_costofsales_amount | amount("-") }}
                        </td>
                        <td v-if="visibleLog" class="amount">
                            {{ d.p_sales_cost | amount("-") }}
                        </td>
                        <td v-if="visibleLog" class="bordercol"></td>
                        <td>
                            <div class="amount no89">
                                {{ d.c_sales_amount | amount("-") }}
                            </div>
                            <div
                                v-if="visibleLog"
                                :class="[
                                    'amount',
                                    'diff',
                                    {
                                        minus: d.cp_sales < 0
                                    }
                                ]"
                            >
                                ({{ d.cp_sales | amountdiff }})
                            </div>
                        </td>
                        <td>
                            <div class="amount no89">
                                {{ d.c_costofsales_amount | amount("-") }}
                            </div>
                            <div
                                v-if="visibleLog"
                                :class="[
                                    'amount',
                                    'diff',
                                    {
                                        minus: d.cp_cost < 0
                                    }
                                ]"
                            >
                                ({{ d.cp_cost | amountdiff }})
                            </div>
                        </td>
                        <td>
                            <div class="amount no89">
                                {{ d.c_sales_cost | amount("-") }}
                            </div>
                            <div
                                v-if="visibleLog"
                                :class="[
                                    'amount',
                                    'diff',
                                    {
                                        minus: d.cp_sales_cost < 0
                                    }
                                ]"
                            >
                                ({{ d.cp_sales_cost | amountdiff }})
                            </div>
                        </td>

                        <!-- <td v-if="visibleLog"></td>
                        <td v-if="visibleLog" class="amount">
                            {{
                                calcDiff(d.c_sales_amount, d.p_sales_amount, 0)
                                    | amountdiff
                            }}
                        </td>
                        <td v-if="visibleLog" class="amount">
                            {{
                                calcDiff(
                                    d.c_costofsales_amount,
                                    d.p_costofsales_amount,
                                    0
                                ) | amountdiff
                            }}
                        </td>
                        <td v-if="visibleLog" class="amount">
                            {{
                                calcDiff(
                                    calcDiff(
                                        d.c_sales_amount,
                                        d.p_costofsales_amount,
                                        0
                                    ),
                                    calcDiff(
                                        d.p_sales_amount,
                                        d.p_costofsales_amount,
                                        0
                                    ),
                                    0
                                ) | amountdiff
                            }}
                        </td> -->
                    </tr>
                </tbody>
                <tfoot>
                    <tr style="border-top-style:double;">
                        <td></td>
                        <td style="text-align:right;">
                            合計
                            <div
                                v-if="!visibleAllDetail && visibleLog"
                                class="note"
                            >
                                ※差分のないデータの値を含みます
                            </div>
                        </td>
                        <td v-if="visibleLog" class="bordercol"></td>
                        <td v-if="visibleLog" class="amount">
                            {{ total.p_sales_amount | amount("-") }}
                        </td>
                        <td v-if="visibleLog" class="amount">
                            {{ total.p_costofsales_amount | amount("-") }}
                        </td>
                        <td v-if="visibleLog" class="amount">
                            {{ total.p_sales_cost | amount("-") }}
                        </td>
                        <td v-if="visibleLog" class="bordercol"></td>
                        <td>
                            <div class="amount no89">
                                {{ total.c_sales_amount | amount("-") }}
                            </div>
                            <div
                                v-if="visibleLog"
                                :class="[
                                    'amount',
                                    'diff',
                                    {
                                        minus: total.cp_sales < 0
                                    }
                                ]"
                            >
                                ({{ total.cp_sales | amountdiff }})
                            </div>
                        </td>
                        <td>
                            <div class="amount no89">
                                {{ total.c_costofsales_amount | amount("-") }}
                            </div>
                            <div
                                v-if="visibleLog"
                                :class="[
                                    'amount',
                                    'diff',
                                    {
                                        minus: total.cp_cost < 0
                                    }
                                ]"
                            >
                                ({{ total.cp_cost | amountdiff }})
                            </div>
                        </td>
                        <td>
                            <div class="amount no89">
                                {{ total.c_sales_cost | amount("-") }}
                            </div>
                            <div
                                v-if="visibleLog"
                                :class="[
                                    'amount',
                                    'diff',
                                    {
                                        minus: total.cp_sales_cost < 0
                                    }
                                ]"
                            >
                                ({{ total.cp_sales_cost | amountdiff }})
                            </div>
                        </td>
                        <!-- <td v-if="visibleLog"></td>
                        <td v-if="visibleLog" class="amount">
                            {{
                                calcDiff(
                                    total.c_sales_amount,
                                    total.p_sales_amount,
                                    0
                                ) | amountdiff
                            }}
                        </td>
                        <td v-if="visibleLog" class="amount">
                            {{
                                calcDiff(
                                    total.c_costofsales_amount,
                                    total.p_costofsales_amount,
                                    0
                                ) | amountdiff
                            }}
                        </td>
                        <td v-if="visibleLog" class="amount">
                            {{
                                calcDiff(
                                    calcDiff(
                                        total.c_sales_amount,
                                        total.p_costofsales_amount,
                                        0
                                    ),
                                    calcDiff(
                                        total.p_sales_amount,
                                        total.p_costofsales_amount,
                                        0
                                    ),
                                    0
                                ) | amountdiff
                            }}
                        </td> -->
                    </tr>
                </tfoot>
            </table>
        </transition>
    </div>
</template>

<script>
import DateUtils from "@/DateUtils.js";

export default {
    name: "SalesLogList",
    props: {
        startdate: Date,
        enddate: Date,
        perioddiv: Number || String,
        salesorderStatus: Array,
        purchaseorderStatus: Array,
        department: Object,
        logdate: Date,
        visibleLog: Boolean,
        visibleAllDetail: Boolean
    },
    data: function() {
        return {
            loading: true,
            list_data: [],
            total: {}
        };
    },
    methods: {
        visibleRow: function(d) {
            let ret = false;
            if (this.visibleAllDetail || !this.visibleLog) {
                ret = true;
            } else {
                let a = this.calcDiff(d.c_sales_amount, d.p_sales_amount, 0);
                let b = this.calcDiff(
                    d.c_costofsales_amount,
                    d.p_costofsales_amount,
                    0
                );
                ret = a !== 0 || b !== 0;
            }
            return ret;
        },
        calcDiff: function(a, b, dft) {
            let ret = null;
            if (!isNaN(dft)) {
                if (a === null) a = dft;
                if (b === null) b = dft;
            }
            if (a !== null && b !== null) {
                ret = a - b;
            }
            return ret;
        },
        makeParams: function(sdate, edate) {
            let params = {};
            let sd = sdate ? sdate : this.startdate;
            let ed = edate ? edate : this.enddate;

            params["period"] = this.dateTo8Str(sd) + "-" + this.dateTo8Str(ed);

            if (this.salesorderStatus && this.salesorderStatus.length > 0) {
                params["sosts"] = this.salesorderStatus;
            }
            if (
                this.purchaseorderStatus &&
                this.purchaseorderStatus.length > 0
            ) {
                params["posts"] = this.purchaseorderStatus;
            }
            return params;
        },
        execute: async function() {
            this.loading = true;
            const params = this.makeParams();
            let data = [];
            let ttl = {
                c_sales_amount: 0,
                c_costofsales_amount: 0,
                p_sales_amount: 0,
                p_costofsales_amount: 0,
                cp_sales: 0,
                cp_cost: 0,
                cp_sales_cost: 0
            };
            await this.readCurrentData(params, data, ttl);
            if (this.visibleLog && this.logdate) {
                // let comp = this.getCompany();
                // let fh = DateUtils.getFiscalYearAndHalfsign(comp, this.logdate);
                // let dts = DateUtils.getFiscalRangeDate(
                //     comp,
                //     fh.fiscalyear,
                //     this.perioddiv
                // );
                // const hparams = this.makeParams(dts.start, dts.end);
                await Promise.allSettled([
                    this.readLogDetailSls(params, data, ttl),
                    this.readLogDetailCos(params, data, ttl)
                ]);
                ttl.cp_sales_cost = this.calcDiff(ttl.cp_sales, ttl.cp_cost, 0);
                ttl.p_sales_cost = this.calcDiff(
                    ttl.p_sales_amount,
                    ttl.p_costofsales_amount,
                    0
                );
            }

            for (let k in data) {
                let d = data[k];
                d["p_sales_cost"] = this.calcDiff(
                    d.p_sales_amount,
                    d.p_costofsales_amount,
                    0
                );
                d["cp_sales"] = this.calcDiff(
                    d.c_sales_amount,
                    d.p_sales_amount,
                    0
                );
                d["cp_cost"] = this.calcDiff(
                    d.c_costofsales_amount,
                    d.p_costofsales_amount,
                    0
                );
                d["cp_sales_cost"] = this.calcDiff(
                    d.c_sales_cost,
                    d.p_sales_cost,
                    0
                );
                this.list_data.push(d);
            }
            this.total = ttl;
            this.loading = false;
        },
        readCurrentData: function(params, data, ttl) {
            return this.readDataToArray(
                this.makeurl("stgt", "detail", this.department.id, params),
                dlist => {
                    let tsls = 0;
                    let tcos = 0;
                    for (let item of dlist) {
                        let key = item["salesorder_id"];
                        let sls = new Number(item["sales_amount"]);
                        let cos = new Number(item["costofsales_amount"]);
                        tsls += sls;
                        tcos += cos;
                        data[key] = {
                            salesorder_id: key,
                            salesorder_subject: item["salesorder_subject"],
                            c_sales_amount: sls,
                            c_costofsales_amount: cos,
                            c_sales_cost: this.calcDiff(sls, cos, 0),
                            p_sales_amount: null,
                            p_costofsales_amount: null,
                            p_sales_cost: null,
                            salesorder_customer_code: item["salesorder_customer_code"],
                            salesorder_customer_name: item["salesorder_customer_name"]
                        };
                    }
                    ttl.c_sales_amount = tsls;
                    ttl.c_costofsales_amount = tcos;
                    ttl.c_sales_cost = tsls - tcos;
                    // this.list_data = data;
                    ttl.cp_sales = 0;
                    ttl.cp_cost = 0;
                    ttl.cp_sales_cost = 0;
                }
            );
        },
        readLogDetailSls: function(params, data, ttl) {
            return this.readDataToArray(
                this.makeurl(
                    "stgt",
                    "log",
                    "detail",
                    DateUtils.toRequestDate(this.logdate),
                    this.department.id,
                    "sls",
                    params
                ),
                dlist => {
                    let tsls = 0;
                    for (let item of dlist) {
                        let key = item["salesorder_id"];
                        let obj = data[key];
                        let sls = new Number(item["sales_amount"]);
                        tsls += sls;
                        if (!obj) {
                            obj = {
                                salesorder_id: key,
                                salesorder_subject: item["salesorder_subject"],
                                c_sales_amount: null,
                                c_costofsales_amount: null,
                                c_sales_cost: null,
                                p_sales_amount: null,
                                p_costofsales_amount: null,
                                salesorder_customer_code: item["salesorder_customer_code"],
                                salesorder_customer_name: item["salesorder_customer_name"]
                            };
                            data[key] = obj;
                        }
                        obj["p_sales_amount"] = sls;
                    }
                    ttl.p_sales_amount = tsls;

                    ttl.cp_sales = this.calcDiff(
                        ttl.c_sales_amount,
                        ttl.p_sales_amount,
                        0
                    );
                }
            );
        },
        readLogDetailCos: function(params, data, ttl) {
            return this.readDataToArray(
                this.makeurl(
                    "stgt",
                    "log",
                    "detail",
                    DateUtils.toRequestDate(this.logdate),
                    this.department.id,
                    "cos",
                    params
                ),
                dlist => {
                    let tcos = 0;
                    for (let item of dlist) {
                        let key = item["salesorder_id"];
                        let obj = data[key];
                        let cos = new Number(item["costofsales_amount"]);
                        tcos += cos;
                        if (!obj) {
                            obj = {
                                salesorder_id: key,
                                salesorder_subject: item["salesorder_subject"],
                                c_sales_amount: null,
                                c_costofsales_amount: null,
                                c_sales_cost: null,
                                p_sales_amount: null,
                                p_costofsales_amount: null,
                                salesorder_customer_code: item["salesorder_customer_code"],
                                salesorder_customer_name: item["salesorder_customer_name"]
                            };
                            data[key] = obj;
                        }
                        obj["p_costofsales_amount"] = cos;
                    }
                    ttl.p_costofsales_amount = tcos;

                    ttl.cp_cost = this.calcDiff(
                        ttl.c_costofsales_amount,
                        ttl.p_costofsales_amount,
                        0
                    );
                }
            );
        }
    },
    created: async function() {
        await this.loaddivs("sosts");
        await this.execute();
    }
};
</script>
<style scoped>
.notarget {
    opacity: 0.375;
}
.no89 {
    padding-right: 0.75ex;
}
.diff {
    opacity: 0.5;
}
.note {
    opacity: 0.75;
    font-size: 75%;
}

table.dtable thead tr > * {
    text-align: center;
    background-color: var(--main-bg-color);
    color: var(--title-text-color);
}
table.dtable thead tr:first-child > * {
    position: sticky;
    top: 0;
}
table.dtable thead tr:first-child > *:not([rowspan="2"]) {
    border-bottom-width: 1px;
    height: 26px;
}
table.dtable thead tr:last-child > * {
    position: sticky;
    top: 26px;
    border-top: 0;
}
table.dtable tbody:nth-child(2n + 1) {
    background: var(--odd-bg-color);
}

table.dtable td {
    vertical-align: top;
    border-right-style: dotted;
    border-left-style: dotted;
}

table.dtable td {
    padding: 3px 10px;
}

table.dtable .bordercol {
    max-width: 5px;
    width: 5px;
    min-width: 5px;
    font-size: 3px;
    padding: 0;
    overflow: hidden;
}

table.dtable tr.rownormal {
    height: 3rem;
    min-height: 3rem;
    max-height: inherit;
}
table.dtable tr.rowhide {
    height: 5px;
    min-height: 5px;
    max-height: 5px;

    overflow: hidden;
}
.minus {
    color: var(--error-text-color);
}
.code {
    font-size: 0.85em;
    color: var(--text-weak-color);
}
.code:after {
    content: " : ";
}
</style>
