<template>
    <div>
        <div class="value cell">
            <slot name="value">{{ getValue("") | amountf }}</slot>
        </div>
        <fieldset class="comparedata" v-if="state.visibleLog">
            <!-- <legend>{{ state.logdate | md }}</legend> -->

            <div :class="['diff', 'cell', { minus: isMinus('_log') }]">
                <slot name="logdiff">{{ getDiff("_log") | amountdiff }}</slot>
            </div>
            <div class="prevalue scell">
                <slot name="logvalue">{{ getValue("_log") | amountf }}</slot>
            </div>
        </fieldset>
        <fieldset class="comparedata" v-if="state.visibleCmp">
            <!-- <legend>{{ dispCmpYear() }}</legend> -->

            <div :class="['diff', 'cell', { minus: isMinus('_cmp') }]">
                <slot name="cmpdiff">{{ getDiff("_cmp") | amountdiff }}</slot>
            </div>
            <div class="prevalue scell">
                <slot name="cmpvalue">{{ getValue("_cmp") | amountf }}</slot>
            </div>
        </fieldset>
    </div>
</template>

<script>
export default {
    name: "SalesTargetTableCell",
    props: {
        log: Boolean,
        cmp: Boolean,
        obj: [Object, Array],
        prp: String,
        state: Object //  cmpyearobj:, visibleLog, visibleCmp, logdate
    },
    methods: {
        getStatValue: function(prp) {
            let ret = "";
            if (this.obj && this.obj[prp]) {
                ret = this.obj[prp];
            }
            return ret;
        },
        getValue: function(suffix) {
            return this.getStatValue(this.prp + suffix);
        },
        getDiff: function(suffix) {
            let a = this.getStatValue(this.prp);
            let b = this.getStatValue(this.prp + suffix);
            return a - b;
        },
        isMinus: function(suffix) {
            let a = this.getStatValue(this.prp);
            let b = this.getStatValue(this.prp + suffix);
            return a - b < 0;
        },
        dispCmpYear: function() {
            let ret = this.state.cmpyearobj.key;
            if (ret) {
                // ret += "";
                // ret = "'" + ret.slice(-2);
                switch (this.state.perioddiv) {
                    case 0:
                        ret += "全";
                        break;
                    case 1:
                        ret += "上";
                        break;
                    case 2:
                        ret += "下";
                        break;
                }
            }
            return ret;
        }
    }
};
</script>

<style scoped>
.logdata {
    height: 1rem;
    opacity: 0.5;
}
fieldset.comparedata {
    border-style: none;
    padding: 2px 0 3px 0;
    margin: 5px 0 0 0;
    height: 2.4rem;
    border-top: 1px dotted var(--border-color);
}
fieldset.comparedata > legend {
    font-size: 0.75rem;
    text-align: left;
    letter-spacing: 0.5px;
    padding: 0 5px 0 0;
    vertical-align: bottom;
}
.value,
.prevalue {
    padding-right: 0.75ex;
}
.prevalue {
    font-size: 85%;
    letter-spacing: 1.25px;
    opacity: 0.75;
}
.diff {
    font-size: 85%;
    letter-spacing: 1.25px;
    opacity: 0.5;
}
.diff::before {
    content: "(";
}
.diff::after {
    content: ")";
}
.cell {
    height: 1rem;
}
.scell {
    height: 0.9rem;
}
.minus {
    color: var(--error-text-color);
}
</style>
